import React, { HtmlHTMLAttributes, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import MailchimpSubscribe from "react-mailchimp-subscribe";
const Form = styled.form`
  width: 100%;
  max-width: 718px;
  padding: 46px 64px;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  margin: 0 auto;

  input,
  textarea {
    background-color: #f8f8f8;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #676767;
    margin-bottom: 14px;
    padding: 16px 32px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 21px;
    padding: 25px 25px;
  }
`;

const TextArea = styled.textarea`
  height: 186px;
`;
const Button = styled.button`
  font-family: "Poppins";
  font-style: normal;
  max-width: 590px;
  width: 100%;
  height: 56px;
  left: 429px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  top: 1076px;
  background: #1da1f2;
  border-radius: 10px;
  border: none;
`;


const SelectDropdown = styled.select`
    background-color: rgb(248, 248, 248);
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: rgb(103, 103, 103);
    margin-bottom: 14px;
    padding: 16px 32px;
`;

const DropDown = styled.select`
  outline: none;
  background-color: #f8f8f8;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  margin-bottom: 24px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  padding: 16px 31px;
  color: #676767;

  :hover {
    border: 1px solid #ced4da;
    background-color: #f8f8f8;
  }
`;
const Drop = styled.option`
  background-color: #f8f8f8;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  margin-bottom: 24px;
  border: 1px solid #ced4da;
  padding: 16px 31px;
  color: #676767;
`;
const Err = styled.p`
color: red;
font-size: 14px;
margin-top: -8px;
margin-left: 10px;
`
interface IFormInput {
  email: string;
  name: string;
  orderNumber: string;
  message: string;
  reason: string;
}

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is mendatory")
    .min(5, "Name must be at 5 char long"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is mendatory"),
  number: Yup.string()
    .required("Order number is mendatory")
    .min(10, "Order number must be at 10 char long"),
  message: Yup.string()
    .required("Please type some message here")
    .min(10, "message must be at 10 char long"),
});
const postUrl = `https://dev.us21.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}&f_id=00a1f9e1f0`;
const ContactForm = () => {
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    reason: "",
    orderNumber: "",
    message: "",
  });
  const [err, setErr] = useState({
    name: "",
    email: "",
    reason: "",
    orderNumber: "",
    message: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const handleOnChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name as keyof IFormInput;
    setErr((prop) => {
      if (!prop) return prop;
      return { ...prop, [name]: "" }
    });
    setFormValue((old) => {
      if (!old) return old;
      return {
        ...old,
        [name]: value,
      };
    });
  };

  const fillErrorState = (err: string, field: string) => {
    setErr((prop) => {
      if (!prop) return prop;
      return { ...prop, [field]: err }
    });
  };
  return (
    <>
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <div className="w-100">
            <Form
              className="mb-3"
              onSubmit={(e) => {
                e.preventDefault();
                if (formValue.name === "") {
                  fillErrorState("Name is Required", "name");
                } else if (formValue.email === "") {
                  fillErrorState("Email is Required", "email");
                } else if (formValue.orderNumber === "") {
                  fillErrorState("Order Number is Required", "orderNumber");
                } else if (formValue.message === "") {
                  fillErrorState("Message is Required", "message");
                } else {
                  subscribe({
                    EMAIL: formValue.email,
                    FNAME: formValue.name,
                    MMERGE2: formValue.reason,
                    MMERGE4: formValue.message,
                    MMERGE3: formValue.orderNumber,
                  } as any);
                }
              }}
            >
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Name"
                onChange={handleOnChange}
                name="name"
              />
              {(err.name !== "") && <Err>{err.name as any}</Err>}
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                id="exampleFormControlInput1"
                onChange={handleOnChange}
                name="email"
              />
              {(err.email !== "") && <Err>{err.email as any}</Err>}
              {/* <div className="dropdown">
                <DropDown
                  className="w-100"
                  onChange={handleOnChange}
                  name="reason"
                >
                  <Drop value="">Reason (Optional)</Drop>
                  <Drop value="General enquiries"> General enquiries</Drop>
                  <Drop value="About my order">About my order</Drop>
                  <Drop value="Customer & buld orders">
                    Customer & buld orders
                  </Drop>
                  <Drop value="Technical Support">Technical Support</Drop>
                  <Drop value="Other">Other</Drop>
                </DropDown>
              </div> */}
              <SelectDropdown className="form-select w-100" aria-label="Default select example"
                onChange={handleOnChange}
                name="reason">
                <Drop value="">Reason (Optional)</Drop>
                <Drop value="General enquiries"> General enquiries</Drop>
                <Drop value="About my order">About my order</Drop>
                <Drop value="Customer & buld orders">
                  Customer & buld orders
                </Drop>
                <Drop value="Technical Support">Technical Support</Drop>
                <Drop value="Other">Other</Drop>
              </SelectDropdown>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Order Number (Optional)"
                onChange={handleOnChange}
                name="orderNumber"
              />
              {(err.orderNumber !== "") && <Err>{err.orderNumber as any}</Err>}
              <TextArea
                className="form-control"
                id="exampleFormControlTextarea1"
                placeholder="Message"
                onChange={handleOnChange}
                name="message"
              ></TextArea>
              {(err.message !== "") && <p>{Err.message as any}</p>}
              <Button type="submit">Send</Button>
            </Form>

            {status === "sending" && (
              <div className="text-center fs-3" style={{ color: "blue" }}>
                sending...{message}
              </div>
            )}
            {status === "error" && (
              <div className="text-center fs-3" style={{ color: "red" }}>
                {message as any}
              </div>
            )}
            {status === "success" && (
              <div className="text-center fs-3" style={{ color: "green" }}>
                {message}
              </div>
            )}
          </div >
        )}
      />
    </>
  );
};

export default ContactForm;
