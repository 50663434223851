import React from "react";
import styled from "styled-components";
import ContactForm from "../components/ContactForm/ContactForm";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import { useStaticQuery, graphql } from "gatsby";

const Section = styled.section`
  padding-top: calc(2rem + 70px);
  .isBackground {
    background-color: #f9fdff;
    padding-top: 4rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
`;

const Heading = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 90px;
  letter-spacing: 0.0125em;
  color: #3d3d3d;
  @media (max-width: 425px) {
    font-size: 34px;
  }
`;

const Paragraph = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #676767;
  padding: 0;
  margin: 0;
  @media (max-width: 425px) {
    font-size: 15px;
    line-height: 21px;
  }
`;

const FormDiv = styled.div`
  @media (max-width: 767px) {
    font-size: 34px;
    padding-top: 34px;
  }
`;

const contact = () => {
  const getContactUsData = useStaticQuery(graphql`
    query contactPageData {
      strapiContactUsPage {
        contactPageSeo {
          title
          description
        }
      }
    }
  `);

  return (
    <>
      <Seo
        title={getContactUsData.strapiContactUsPage.contactPageSeo.title}
        description={
          getContactUsData.strapiContactUsPage.contactPageSeo.description
        }
        canonical="/contact"
        keywords="contact us"
      />
      <Layout background={true}>

        <Section>
          <div className="isBackground">
            <div className="container text-center">
              <Heading>Get in Touch</Heading>
              <div className="pb-4 mb-4">
                <Paragraph>If you have any questions about our</Paragraph>
                <Paragraph>
                  Instagram growth services, please send us a message below.
                </Paragraph>
              </div>
            </div>
          </div>
          <FormDiv className="d-flex justify-content-center w-100">
            <ContactForm />
          </FormDiv>
        </Section>
      </Layout>
    </>
  );
};

export default contact;